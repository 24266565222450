import PropTypes from 'prop-types';
import styled from 'styled-components';

// Truncates multiline text
const TextClamp = ({ lines, children, ...rest }) => {
  if (!children) {
    return null;
  }

  return (
    <StyledSpan lines={lines} {...rest}>
      {children}
    </StyledSpan>
  );
};

const StyledSpan = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: ${({ lines }) => lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: ${({ textTransform }) => textTransform};
`;

TextClamp.propTypes = {
  lines: PropTypes.number,
  children: PropTypes.node,
};

TextClamp.defaultProps = {
  lines: 2,
  children: null,
};

export { TextClamp };
