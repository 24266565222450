import PropTypes from 'prop-types';

import { Button } from '@components/button';
import { Flex } from '@components/grid';
import { Icon } from '@components/icons';
import { P, Subheading } from '@components/typography';

const DefaultState = ({ title, subtitle, onUndo }) => (
  <Flex flexDirection="column" alignItems="center" justifyContent="center" flex={1} px="1.5rem" mb={2}>
    <Icon name="flameSolid" fill="grey200" height="102px" width="54px" />
    <Subheading role="heading" aria-level="3" variant={2} textAlign="center" mt={1} mb="0.5rem">
      {title}
    </Subheading>
    {subtitle && (
      <P width="100%" textAlign="center" color="greyPrimary">
        {subtitle}
      </P>
    )}
    {onUndo && (
      <Button width="100%" mt="1rem" variant="secondary" onClick={onUndo}>
        Undo
      </Button>
    )}
  </Flex>
);

DefaultState.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  onUndo: PropTypes.func,
};

DefaultState.defaultProps = {
  subtitle: null,
  onUndo: null,
};

export { DefaultState };
