import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Flex } from '../grid';

const CardWrapper = ({ children, onClick, isItemInCart, ...props }) => {
  return (
    <Wrapper onClick={onClick} isItemInCart={isItemInCart} role={onClick ? 'button' : undefined} {...props}>
      {children}
    </Wrapper>
  );
};

CardWrapper.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  isItemInCart: PropTypes.bool,
};

CardWrapper.defaultProps = {
  children: undefined,
  onClick: undefined,
  isItemInCart: false,
};

const Wrapper = styled(Flex)`
  position: relative;
  height: 100%;
`;

export { CardWrapper };
