import { first, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import StyledSystemPropTypes from '@styled-system/prop-types';

import { Box } from '../grid';
import { Card } from './card';
import { cmsImagePropTypes } from '../../prop-types';
import { H3, P } from '../typography';
import { TextClamp } from '../text-clamp';

const FeatureCard = ({ item, backgroundColor, imageHeight }) => (
  <Card
    backgroundColor={backgroundColor}
    image={{ ...item.image, width: 424, height: 400 }}
    imageWidth={1}
    imageHeight={imageHeight}
    contentWidth={1}
    href={get(first(item.internalLink), 'uri', item.href)}
  >
    <Box data-testid="feature-card">
      {item.heading && (
        <H3 textAlign="center" mb="0.5rem">
          <TextClamp lines={2}>{item.heading}</TextClamp>
        </H3>
      )}
      {item.description && (
        <P variant={3} textAlign="center">
          <TextClamp>{item.description}</TextClamp>
        </P>
      )}
    </Box>
  </Card>
);

FeatureCard.propTypes = {
  backgroundColor: PropTypes.oneOf(['light', 'dark']),
  item: PropTypes.shape({
    description: PropTypes.string,
    heading: PropTypes.string,
    image: cmsImagePropTypes,
    href: PropTypes.string,
    internalLink: PropTypes.arrayOf(
      PropTypes.shape({
        uri: PropTypes.string,
      })
    ),
  }).isRequired,
  imageHeight: StyledSystemPropTypes.layout.height,
};

FeatureCard.defaultProps = {
  backgroundColor: 'light',
  imageHeight: { sm: '100px', md: '200px', lg: '300px' },
};

export { FeatureCard };
