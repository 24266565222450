import { Card } from './card';
import { Flex } from '../grid';
import { recipeCardPropType } from '../../prop-types';
import { RecipeTimes } from '../recipe-times';
import { Subheading } from '../typography';
import { TextClamp } from '../text-clamp';

const RecipeCard = ({ item }) => (
  <Card
    flexWrap="no-wrap"
    flexDirection="column"
    contentWidth={1}
    href={item.href}
    image={item.image}
    imageHeight="100%"
    imageWidth={1}
    justifyContent="flex-end"
    overlayContent
  >
    <Subheading color="white" variant={2} mb={1}>
      <TextClamp lines={2}>{item.heading}</TextClamp>
    </Subheading>
    <Flex color="white">
      <RecipeTimes prepTime={item.prepTime} cookTime={item.cookTime} />
    </Flex>
  </Card>
);

RecipeCard.propTypes = {
  item: recipeCardPropType.isRequired,
};

export { RecipeCard };
