import { propType as systemPropType } from '@styled-system/prop-types';
import PropTypes from 'prop-types';

import { Box } from '../grid';

const DecalWrapper = ({ leftDecal, rightDecal, width, children, decalChildrenStyle, overflow, ...rest }) => {
  return (
    <Box position="relative" overflow={overflow} {...rest}>
      <Box position="absolute" top={0} left={0} width={width} height="100%">
        {leftDecal}
      </Box>
      <Box position="inherit" {...decalChildrenStyle}>
        {children}
      </Box>
      <Box position="absolute" top={0} right={0} width={width} height="100%">
        {rightDecal}
      </Box>
    </Box>
  );
};

DecalWrapper.propTypes = {
  leftDecal: PropTypes.node,
  rightDecal: PropTypes.node,
  width: systemPropType,
  children: PropTypes.node,
  decalChildrenStyle: systemPropType,
  overflow: systemPropType,
};

DecalWrapper.defaultProps = {
  leftDecal: null,
  rightDecal: null,
  width: { _: '20%', md: '12%', lg: '10%' },
  children: null,
  decalChildrenStyle: { zIndex: 1 },
  overflow: 'visible',
};

export { DecalWrapper };
