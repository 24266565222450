import styled from 'styled-components';

import { Flex } from '../../grid';
import { Icon } from '../../icons';

const DefaultImage = ({ ...props }) => (
  <StyledFlex
    data-testid="default-image"
    justifyContent="center"
    alignItems="center"
    backgroundColor="grey100"
    {...props}
  >
    <Icon name="flameSolid" fill="white" height="100%" width="40%" />
  </StyledFlex>
);

const StyledFlex = styled(Flex)`
  transition: 200ms ease;
  transform: scale(1);
`;

export { DefaultImage };
