import { colors } from '@nandosaus/constants';
import { variant as styledVariant } from 'styled-system';
import styled from 'styled-components';

import { Flex } from '../grid';

const backgroundColorVariants = styledVariant({
  variants: {
    transparent: {
      backgroundColor: 'transparent',
      color: colors.black,
    },
    light: {
      backgroundColor: colors.white,
      color: colors.black,
    },
    dark: {
      backgroundColor: colors.black,
      color: colors.white,
    },
  },
});

export const Wrapper = styled(Flex)`
  ${backgroundColorVariants};
  ${({ role }) => role === 'button' && `cursor: pointer;`};
`;
