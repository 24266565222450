import { includes, isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Flex } from '../grid';
import { P } from '../typography';

const getNumberOfTagsToShow = ({ labels }) => {
  if (includes(labels, 'VO') || includes(labels, 'VGO')) {
    return 1;
  }

  return 2;
};

const Tags = ({ color, labels, size, fontWeight }) => {
  if (isEmpty(labels)) {
    return null;
  }

  const labelVariant = size === 'small' ? 3 : 1;
  const labelRightMargin = labels.length > 1 && size === 'small' ? '0.25rem' : '0.5rem';

  const TAGS_TO_BE_SHOWN = size === 'small' ? getNumberOfTagsToShow({ labels }) : labels.length;
  const visibleItems = labels.slice(0, TAGS_TO_BE_SHOWN);

  return (
    <Flex data-testid="tags" color={color}>
      {map(visibleItems, value => (
        <P key={value} variant={labelVariant} mr={labelRightMargin} fontWeight={fontWeight}>
          {value}
        </P>
      ))}
      {labels.length > TAGS_TO_BE_SHOWN && (
        <P key="overflow-tag" variant={labelVariant} fontWeight={fontWeight}>
          +{labels.length - TAGS_TO_BE_SHOWN}
        </P>
      )}
    </Flex>
  );
};

Tags.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.string,
};

Tags.defaultProps = {
  size: 'default',
  color: undefined,
  fontWeight: undefined,
};

export { Tags };
