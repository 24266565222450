import { pick } from '@styled-system/props';
import PropTypes from 'prop-types';
import StyledSystemPropTypes from '@styled-system/prop-types';

import { Box, Flex } from '../grid';
import { CardImage } from './card-image';
import { cmsImagePropTypes } from '../../prop-types';
import { ConditionalLink } from './conditional-link';
import { P, Subheading } from '../typography';
import { TextClamp } from '../text-clamp';
import { useIsMinWidth } from '../../hooks/use-is-min-width';

const MosaicCard = ({ item, gridPosition, ...props }) => {
  const styleProps = pick(props);
  const isMobile = !useIsMinWidth('md');
  const { detail, heading, href, image } = item;

  return (
    <ConditionalLink href={href} {...styleProps}>
      <Box gridColumn={gridPosition.image.gridColumn} gridRow={gridPosition.image.gridRow}>
        <CardImage
          image={image ? { ...image, width: gridPosition.image.width, height: gridPosition.image.height } : undefined}
          height={{ _: '150px', md: '100%' }}
          width={{ xs: 1 }}
        />
      </Box>
      <Box gridColumn={gridPosition.content.gridColumn} gridRow={gridPosition.content.gridRow} mb="0.5rem">
        <Flex flexDirection="column" height={{ _: 'auto', md: '65px' }} justifyContent="space-between" pt="0.5rem">
          <Subheading variant={3} data-testid="mosaic-card-heading">
            <TextClamp lines={isMobile ? 2 : 1}>{heading}</TextClamp>
          </Subheading>
          <Flex alignItems="center" mt={{ _: '0.5rem', md: '0' }}>
            <P variant={3} color="greyPrimary">
              {detail}
            </P>
          </Flex>
        </Flex>
      </Box>
    </ConditionalLink>
  );
};

MosaicCard.propTypes = {
  ...StyledSystemPropTypes.flexbox,
  ...StyledSystemPropTypes.layout,
  ...StyledSystemPropTypes.space,

  gridPosition: PropTypes.shape({
    image: PropTypes.shape({
      gridColumn: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      gridRow: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    content: PropTypes.shape({
      gridColumn: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      gridRow: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  }).isRequired,
  item: PropTypes.shape({
    detail: PropTypes.string,
    heading: PropTypes.string,
    href: PropTypes.string,
    image: cmsImagePropTypes,
  }).isRequired,
};

export { MosaicCard };
