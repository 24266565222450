import PropTypes from 'prop-types';
import React from 'react';

import { Flex } from '../grid';
import { P } from '../typography';

const CartIndicator = ({ quantityInCart, ...props }) => (
  <Flex
    height={24}
    width={24}
    borderRadius="50%"
    backgroundColor="success600"
    justifyContent="center"
    alignItems="center"
    {...props}
  >
    <P variant={2} color="white" lineHeight={0} fontWeight="bold">
      {quantityInCart}
    </P>
  </Flex>
);

CartIndicator.propTypes = {
  quantityInCart: PropTypes.number,
};

CartIndicator.defaultProps = {
  quantityInCart: PropTypes.number,
};

export { CartIndicator };
