import { BADGE_TYPES } from '@nandosaus/constants';
import { propType as StyledSystemPropType } from '@styled-system/prop-types';
import { variant } from 'styled-system';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Box } from '../grid';
import { Icon } from '../icons';
import { Label } from '../typography';

const Badge = ({ children, display, type, py, px, icon, size, fontSize, ...SystemProps }) => {
  const labelVariant = {
    medium: 1,
    small: 2,
  }[size];

  return (
    <Box as="span" {...SystemProps}>
      <StyledLabel
        display={display}
        type={type}
        py={py}
        px={px}
        flexDirection="row"
        justifyContent="center"
        fontSize={fontSize}
        variant={labelVariant}
      >
        {children}
        {icon && (
          <Icon
            name={icon}
            fill="white"
            height={{ xs: '15px', lg: '19px' }}
            width={{ xs: '20px', lg: '25px' }}
            pb={{ xs: '2px', lg: 0 }}
          />
        )}
      </StyledLabel>
    </Box>
  );
};

Badge.propTypes = {
  type: PropTypes.oneOf(Object.values(BADGE_TYPES)),
  children: PropTypes.node.isRequired,
  px: StyledSystemPropType,
  py: StyledSystemPropType,
  display: StyledSystemPropType,
  icon: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  fontSize: StyledSystemPropType,
};

Badge.defaultProps = {
  type: BADGE_TYPES.PRIMARY,
  px: '8px',
  display: 'inline-block',
  py: '4px',
  icon: null,
  size: 'small',
  fontSize: null,
};

const badgeVariants = variant({
  prop: 'type',
  variants: {
    [BADGE_TYPES.SECONDARY]: {
      backgroundColor: 'grey600',
      color: 'white',
    },
    [BADGE_TYPES.RED]: {
      backgroundColor: 'red500',
      color: 'white',
    },
    // @TODO: add styles for other variants
  },
});

const StyledLabel = styled(Label).attrs(() => ({ as: 'span' }))`
  ${badgeVariants}
  border-radius: 2px;
  margin-bottom: 0;
  box-decoration-break: clone;
`;

export { Badge };
