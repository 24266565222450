import { Card } from '@components/card/card';
import { CardWrapper } from '@components/card/card-wrapper';
import { CartIndicator } from '@components/card/cart-indicator';
import { Box, Flex } from '@components/grid';
import { Tags } from '@components/tags';
import { TextClamp } from '@components/text-clamp';
import { P } from '@components/typography';
import { useFeature } from '@hooks/use-feature';
import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { productPropTypes } from '@nandosaus/prop-types';
import { useRootStore } from '@nandosaus/state-management';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { analytics } from '../../analytics';

const StyledCartIndicator = styled(CartIndicator)`
  visibility: ${({ visibility }) => visibility};
`;

export const CARD_ASPECT_RATIOS = {
  tall: '3:6',
  square: '1:1',
};

export const getCardImageHeight = (tall, shouldKeepVertical) => {
  if (!tall) return undefined;

  if (shouldKeepVertical) {
    return { xs: '296px', md: '398px' };
  }

  return { xs: '150px', md: '398px' };
};

const MenuItemCard = ({
  item,
  onClick,
  shouldKeepVertical,
  analyticsCategory,
  tall,
  isFullWidth,
  variant,
  hidePrice,
  hideDescription,
  hideCartInfo,
  isReorder,
  isDisabled,
  isLoading,
  role,
}) => {
  const { CartStore, DeliveryStore } = useRootStore();
  const descriptionFlag = useFeature('show-description');

  const { shortestDeliveryTime } = DeliveryStore;

  const getQuantityInCart = () => {
    if (isReorder) {
      const isItemInCart = item.isInCart;
      return {
        quantityInCart: isItemInCart ? 1 : 0,
        isItemInCart,
      };
    }

    const quantityInCart = parseInt(CartStore.productQuantityInCart(item.id), 10);
    const isItemInCart = quantityInCart > 0;
    return {
      quantityInCart,
      isItemInCart,
    };
  };

  const itemPrice = item?.prices?.formattedPrice;
  const itemKilojoules = item?.formattedKilojoules;
  const isEligibleForDeliveryDiscount = item?.isEligibleForDeliveryDiscount;
  const hasOtherSizeEligibleForDeliveryDiscount = item?.hasOtherSizeEligibleForDeliveryDiscount;
  const isDiscountApplied = shortestDeliveryTime?.isDiscountApplied;

  const { quantityInCart, isItemInCart } = getQuantityInCart();

  const handleOnClick = () => {
    analytics.track(ANALYTICS_EVENTS.PRODUCT_CLICKED, {
      product_id: item.plu,
      name: item.name,
      category: item.categoryName,
      analyticsCategory,
    });

    onClick(item);
  };

  return (
    <CardWrapper
      quantityInCart={quantityInCart}
      minHeight={{ xs: '100px', md: '150px' }}
      width={isFullWidth ? '100%' : { xs: '150px', md: '200px' }}
      data-item-id={item.id}
      role={role}
    >
      <Card
        flexWrap={{ xs: 'no-wrap' }}
        flexDirection={{ xs: shouldKeepVertical ? 'column' : 'row', md: 'column' }}
        contentWidth={{ xs: shouldKeepVertical ? 1 : 8 / 12, md: 1 }}
        contentHeight="100%"
        imageHeight={getCardImageHeight(tall, shouldKeepVertical)}
        backgroundColor={variant}
        isItemInCart={isItemInCart}
        onClick={item.isAvailable ? handleOnClick : undefined}
        image={
          item.image
            ? {
                url: item.image,
                alt: item.imageAlt,
                aspectRatio: tall ? CARD_ASPECT_RATIOS.tall : CARD_ASPECT_RATIOS.square,
              }
            : null
        }
        justifyContent="space-between"
        padding={null}
        isEligibleForDeliveryDiscount={isEligibleForDeliveryDiscount || hasOtherSizeEligibleForDeliveryDiscount}
        isDiscountApplied={isDiscountApplied}
        borderRadius={8}
        rounded
        isOutOfStock={!item.isAvailable}
        isDisabled={isDisabled}
        isLoading={isLoading}
      >
        <Flex flexDirection="row" height="100%" py={{ xs: shouldKeepVertical ? '0.75rem' : 1, xxl: '0.75rem' }}>
          <Box mr="auto">
            <P
              variant={2}
              fontWeight="bold"
              mb={item.hasVariablePrice ? '0.5rem' : '1rem'}
              color={!item.isAvailable ? 'grey600' : undefined}
            >
              <TextClamp>{item.groupName || item.name}</TextClamp>
            </P>
            <Box mt={{ md: 'auto' }}>
              {item.hasVariablePrice && !hidePrice && (
                <P variant={4} mb={{ xs: '.125rem', md: '.25rem' }} mt={0.5} color="greyPrimary">
                  From
                </P>
              )}
              <Flex flexDirection={{ xs: 'column', md: 'row' }} mb={0.5} justifyContent="space-between">
                <Flex>
                  {itemPrice && !hidePrice && (
                    <P variant={3} mr=".5rem" color={!item.isAvailable ? 'greyPrimary' : undefined}>
                      {itemPrice}
                    </P>
                  )}
                  {itemKilojoules && (
                    <P color="greyPrimary" variant={3}>
                      {itemKilojoules}
                    </P>
                  )}
                </Flex>

                {item.formattedDietaryPreferencesList && (
                  <Box>
                    <Tags labels={item.formattedDietaryPreferencesList} size="small" />
                  </Box>
                )}
              </Flex>
            </Box>
            {descriptionFlag && !hideDescription && (
              <P mt="0.5rem" variant={3} color="greyPrimary">
                <TextClamp>{item.description}</TextClamp>
              </P>
            )}
          </Box>

          <StyledCartIndicator
            visibility={isItemInCart && !hideCartInfo ? 'unset' : 'hidden'}
            bg="black"
            ml="0.5rem"
            height={{ xs: 22, md: 30 }}
            width={{ xs: 22, md: 30 }}
            minHeight={{ xs: 22, md: 30 }}
            minWidth={{ xs: 22, md: 30 }}
            quantityInCart={quantityInCart}
          />
        </Flex>
      </Card>
    </CardWrapper>
  );
};

MenuItemCard.propTypes = {
  item: productPropTypes.isRequired,
  onClick: PropTypes.func.isRequired,
  shouldKeepVertical: PropTypes.bool,
  analyticsCategory: PropTypes.string,
  tall: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  variant: PropTypes.string,
  hidePrice: PropTypes.bool,
  hideDescription: PropTypes.bool,
  hideCartInfo: PropTypes.bool,
  isReorder: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  role: PropTypes.string,
};

MenuItemCard.defaultProps = {
  shouldKeepVertical: false,
  analyticsCategory: undefined,
  tall: false,
  isFullWidth: false,
  variant: 'light',
  hidePrice: false,
  hideDescription: false,
  hideCartInfo: false,
  isReorder: false,
  isDisabled: false,
  isLoading: false,
  role: null,
};

const ComposedMenuItemCard = observer(MenuItemCard);
export { ComposedMenuItemCard as MenuItemCard };
