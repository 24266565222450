import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import { Card } from './card';
import { productCardPropTypes } from '../../prop-types/product';
import { Subheading } from '../typography';
import { TextClamp } from '../text-clamp';

const ProductCard = ({ children, item, onClick }) => {
  return (
    <Card
      contentWidth={{ xs: 1 }}
      flexDirection={{ xs: 'column' }}
      flexWrap={{ xs: 'no-wrap' }}
      href={item.href}
      image={{ ...item.image, width: 349, height: 250 }}
      imageHeight={{ xs: '250px' }}
      imageWidth={{ xs: 1 }}
      justifyContent="flexStart"
      onClick={onClick}
    >
      {item.heading && (
        <Subheading variant={2} data-testid="product-card-heading">
          <TextClamp>{item.heading}</TextClamp>
        </Subheading>
      )}
      {children}
    </Card>
  );
};

ProductCard.propTypes = {
  children: PropTypes.node,
  item: productCardPropTypes.isRequired,
  onClick: PropTypes.func,
};

ProductCard.defaultProps = {
  children: undefined,
  onClick: undefined,
};

const ProductCardObserver = observer(ProductCard);
export { ProductCardObserver as ProductCard };
