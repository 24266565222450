import { DATE_TIME_FORMATS } from '@nandosaus/constants';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { Card } from './card';
import { cmsImagePropTypes } from '../../prop-types';
import { Flex } from '../grid';
import { Icon } from '../icons';
import { P, Subheading } from '../typography';
import { TextClamp } from '../text-clamp';

const NewsCard = ({ item }) => (
  <Card
    flexWrap={{ xs: 'no-wrap' }}
    flexDirection={{ xs: 'row', md: 'column' }}
    contentWidth={{ xs: 8 / 12, md: 1 }}
    href={item.href}
    image={item.image}
    imageHeight={{ xs: '120px', md: '250px' }}
    imageWidth={{ xs: 4 / 12, md: 1 }}
  >
    <Subheading variant={2} mb={1}>
      <TextClamp lines={2}>{item.heading}</TextClamp>
    </Subheading>
    {item.date && (
      <Flex alignItems="center">
        <Icon name="schedule" fill="greyPrimary" />
        <P data-testid="news-card-date" variant={2} color="greyPrimary" ml="0.5rem">
          {dayjs(item.date).format(DATE_TIME_FORMATS.DATE_DAY_MONTH)}
        </P>
      </Flex>
    )}
  </Card>
);

NewsCard.propTypes = {
  item: PropTypes.shape({
    heading: PropTypes.string,
    date: PropTypes.string,
    href: PropTypes.string,
    image: cmsImagePropTypes,
  }).isRequired,
};

export { NewsCard };
