import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Box, Flex } from '../grid';
import { Label, P } from '../typography';
import { TextClamp } from '../text-clamp';

const StyledP = styled(P)`
  white-space: nowrap;
`;

const RecipeTimes = ({ prepTime, cookTime }) => {
  const showPrepTime = !isNil(prepTime);
  const showCookTime = !isNil(cookTime);

  if (!showPrepTime && !showCookTime) {
    return null;
  }

  return (
    <Flex justifyContent="center">
      {showPrepTime && (
        <Box>
          <Label>Prep</Label>
          <StyledP variant={2}>{prepTime}</StyledP>
        </Box>
      )}
      {showPrepTime && showCookTime && <Box backgroundColor="grey100" height="auto" mx="1rem" width="1px" />}
      {showCookTime && (
        <Box>
          <Label>Cook</Label>
          <P variant={2}>
            <TextClamp lines={1}>{cookTime}</TextClamp>
          </P>
        </Box>
      )}
    </Flex>
  );
};

RecipeTimes.propTypes = {
  prepTime: PropTypes.string,
  cookTime: PropTypes.string,
};

RecipeTimes.defaultProps = {
  prepTime: null,
  cookTime: null,
};

export { RecipeTimes };
