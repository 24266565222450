import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Box, Flex } from '../grid';
import { cmsImagePropTypes } from '../../prop-types';
import { ConditionalLink } from './conditional-link';
import { DefaultImage } from '../default';
import { Image } from '../image';
import { P } from '../typography';
import { TextClamp } from '../text-clamp';

const LinkCard = ({ item }) => (
  <ConditionalLink href={item.externalLink}>
    <Flex bg="white" flexDirection="column">
      <ImageWrapper>
        <Flex width="100%" height="100%" p={1} justifyContent="center">
          {item.image ? (
            <Image src={item.image.url} alt={item.image.alt} objectFit="contain" height="100%" width="100%" />
          ) : (
            <DefaultImage width="100%" height="100%" />
          )}
        </Flex>
      </ImageWrapper>
      <Flex p="0.5rem" justifyContent="center" alignItems="center">
        <P textAlign="center">
          <TextClamp lines={1}>{item.heading}</TextClamp>
        </P>
      </Flex>
    </Flex>
  </ConditionalLink>
);

const ImageWrapper = styled(Box)`
  height: 150px;
  text-align: center;
`;

LinkCard.propTypes = {
  item: PropTypes.shape({
    heading: PropTypes.string,
    image: cmsImagePropTypes,
    externalLink: PropTypes.string,
  }).isRequired,
};

export { LinkCard };
