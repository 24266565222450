import { flexbox, layout, space } from 'styled-system';
import { pick } from '@styled-system/props';
import { startsWith } from 'lodash';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import StyledSystemPropTypes from '@styled-system/prop-types';

const ConditionalLink = ({ children, href, as, ...props }) => {
  const styleProps = pick(props);

  if (!href) {
    return children;
  }

  if (startsWith(href, '/')) {
    return (
      <Link href={href} as={as} passHref>
        <StyledA {...styleProps}>{children}</StyledA>
      </Link>
    );
  }

  return (
    <StyledA href={href} {...styleProps}>
      {children}
    </StyledA>
  );
};

const StyledA = styled.a`
  height: 100%;
  text-decoration: none;

  ${flexbox}
  ${layout}
  ${space}
`;

ConditionalLink.propTypes = {
  ...StyledSystemPropTypes.flexbox,
  ...StyledSystemPropTypes.layout,
  ...StyledSystemPropTypes.space,

  as: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string,
};

ConditionalLink.defaultProps = {
  as: null,
  children: null,
  href: null,
};

export { ConditionalLink };
