import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import StyledSystemPropTypes from '@styled-system/prop-types';

import { LoadingIndicator } from '@components/icons/loading-indicator';
import { Badge } from '../badge';
import { Box, Flex } from '../grid';
import { cmsImagePropTypes } from '../../prop-types';
import { DefaultImage } from '../default';
import { Image } from '../image';

const CardImage = ({
  image,
  height,
  width,
  overflow,
  isEligibleForDeliveryDiscount,
  isDiscountApplied,
  deliveryDiscountSpacing,
  isOutOfStock,
  borderRadius,
  isItemInCart,
  isDisabled,
  isLoading,
}) => {
  const formattedAspectRatio = useMemo(() => {
    if (!image?.aspectRatio) {
      return 1;
    }

    return image.aspectRatio.replace(':', '/');
  }, [image]);

  if (!image || !image.url) {
    return (
      <Container
        flexShrink="0"
        overflow={overflow}
        width={width}
        height={height}
        position="relative"
        borderRadius={borderRadius}
        isItemInCart={isItemInCart}
        aspectRatio={1}
      >
        <DefaultImage data-testid="default-image" width="100%" height="100%" />
      </Container>
    );
  }

  return (
    <Container
      flexShrink="0"
      overflow={overflow}
      width={width}
      height={height}
      position="relative"
      borderRadius={borderRadius}
      isItemInCart={isItemInCart}
      aspectRatio={formattedAspectRatio}
    >
      {isOutOfStock ? (
        <Badge type="secondary" mb="0.5rem" position="absolute" top={1} left={{ xs: '6px', lg: 1 }} zIndex={1}>
          Currently Unavailable
        </Badge>
      ) : (
        isEligibleForDeliveryDiscount && (
          <Box
            mb={deliveryDiscountSpacing || '0.5rem'}
            position="absolute"
            bottom={0}
            left={0}
            right={0}
            textAlign="center"
            zIndex={1}
          >
            {(!isItemInCart || (isItemInCart && !isDiscountApplied)) && (
              <Badge type="red" width="100%" fontSize={{ lg: '14px' }}>
                get free delivery
              </Badge>
            )}
            {isDiscountApplied && isItemInCart && (
              <Badge type="red" width="100%" icon="tick" fontSize={{ lg: '14px' }}>
                offer applied
              </Badge>
            )}
          </Box>
        )
      )}
      {isLoading && (
        <Flex
          position="absolute"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          top={0}
          left={0}
          zIndex={1}
        >
          <LoadingIndicator size="2rem" color="black" />
        </Flex>
      )}
      <StyledImage
        aspectRatio={image.aspectRatio}
        alt={image.alt}
        focalPoint={image.focalPoint}
        isDisabled={isDisabled || isLoading || isOutOfStock}
        objectFit="cover"
        src={image.url}
        width={image.width}
        height={image.height}
        srcSet={image.srcset}
        sizes={image.sizes}
        loading="lazy"
      />
    </Container>
  );
};

const Container = styled(Box)`
  transition: border 150ms ease-in-out 0s;
  border: 1px solid transparent;
  border-color: ${({ isItemInCart, theme }) => isItemInCart && theme.colors.success600};

  [role='button']:hover &,
  a:hover & {
    border-color: ${({ theme }) => theme.colors.success600};
  }

  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
`;

const StyledImage = styled(Image)`
  transition: 400ms ease-out;
  transform: scale(1);
  width: 100% !important;
  height: 100% !important;

  opacity: ${props => (props.isDisabled ? 0.3 : 1)};

  [role='button']:hover &,
  a:hover & {
    transform: scale(1.05);
  }
`;

CardImage.propTypes = {
  image: PropTypes.oneOfType([cmsImagePropTypes, PropTypes.arrayOf(cmsImagePropTypes)]),
  height: StyledSystemPropTypes.layout.height,
  width: StyledSystemPropTypes.layout.width,
  isOutOfStock: PropTypes.bool,
  overflow: PropTypes.string,
  isEligibleForDeliveryDiscount: PropTypes.bool,
  isDiscountApplied: PropTypes.bool,
  deliveryDiscountSpacing: StyledSystemPropTypes.space.margin,
  isItemInCart: PropTypes.bool,
  borderRadius: PropTypes.number,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

CardImage.defaultProps = {
  image: null,
  height: null,
  width: null,
  isOutOfStock: false,
  overflow: 'hidden',
  isEligibleForDeliveryDiscount: false,
  isDiscountApplied: false,
  deliveryDiscountSpacing: null,
  isItemInCart: false,
  borderRadius: 0,
  isDisabled: false,
  isLoading: false,
};

export { CardImage };
