import { get, isNil } from 'lodash';
import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';
import PropTypes from 'prop-types';
import React from 'react';

import { Box, Flex } from '../grid';
import { Card } from './card';
import { CardWrapper } from './card-wrapper';
import { CartIndicator } from './cart-indicator';
import { Icon } from '../icons';
import { P, Subheading } from '../typography';
import { productCardPropTypes } from '../../prop-types/product';
import { TextClamp } from '../text-clamp';

const PerksShopItemCard = ({ item, onClick }) => {
  const { MemberStore, PerksShopStore } = useRootStore();
  const isItemRedeemable = item.pointsPrice <= get(MemberStore, 'profile.balances.points');
  const quantityInCart = PerksShopStore.productQuantityInCart(item.id);
  const isItemInCart = quantityInCart > 0;

  const lockIcon = (
    <Icon
      name="lock"
      fill="greyPrimary"
      height={{ xs: '12px', md: '20px' }}
      width={{ xs: '12px', md: '20px' }}
      ml="0.2rem"
    />
  );

  return (
    <CardWrapper
      onClick={() => onClick(item)}
      isItemInCart={isItemInCart}
      minHeight={{ xs: '330px' }}
      maxHeight={{ xs: '340px', md: '390px' }}
    >
      <Card
        contentWidth={{ xs: 1 }}
        flexDirection={{ xs: 'column' }}
        flexWrap={{ xs: 'no-wrap' }}
        href={item.href}
        image={{ ...item.image, width: 349, height: 250 }}
        imageHeight={{ xs: '250px' }}
        imageWidth={{ xs: 1 }}
        justifyContent="flexStart"
        onClick={onClick}
        padding={null}
      >
        <Box position="relative" height="100%">
          {isItemInCart && (
            <CartIndicator
              position="absolute"
              right={{ xs: '12px', md: '6px' }}
              top={{ xs: null, md: '-20px' }}
              bottom={{ xs: '12px', md: null }}
              height={{ xs: 22, md: 40 }}
              width={{ xs: 22, md: 40 }}
              quantityInCart={quantityInCart}
            />
          )}
          <Box p={{ xs: 1 }}>
            {item.heading && (
              <Subheading variant={2} data-testid="product-card-heading">
                <TextClamp>{item.heading}</TextClamp>
              </Subheading>
            )}
            <Flex alignItems="center">
              {!isNil(item.hasStock) && !item.hasStock && (
                <>
                  <P variant={2} color="greyPrimary">
                    Out of stock
                  </P>
                  {lockIcon}
                </>
              )}
              {item.hasStock && !isNil(item.pointsPrice) && (
                <>
                  <P variant={2} color={isItemRedeemable ? 'black' : 'greyPrimary'}>
                    {item.pointsPrice} points
                  </P>
                  {!isItemRedeemable && lockIcon}
                </>
              )}
            </Flex>
          </Box>
        </Box>
      </Card>
    </CardWrapper>
  );
};

PerksShopItemCard.propTypes = {
  item: productCardPropTypes.isRequired,
  onClick: PropTypes.func,
};

PerksShopItemCard.defaultProps = {
  onClick: undefined,
};

const PerksShopItemCardObserver = observer(PerksShopItemCard);
export { PerksShopItemCardObserver as PerksShopItemCard };
