import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '../grid';
import { Card } from './card';
import { cmsImagePropTypes } from '../../prop-types';
import { P, Subheading } from '../typography';
import { TextClamp } from '../text-clamp';

const PageLinkCard = ({ item }) => (
  <Card
    image={item.image}
    imageWidth={{ xs: 1 }}
    imageHeight={{ xs: '230px' }}
    contentWidth={{ xs: 1 }}
    href={get(item, 'link')}
  >
    <Box data-testid="page-link-card">
      {item.heading && (
        <Subheading variant={3} textAlign="center" mb="0.5rem">
          <TextClamp lines={1}>{item.heading}</TextClamp>
        </Subheading>
      )}
      {item.description && (
        <P variant={3} textAlign="center">
          <TextClamp>{item.description}</TextClamp>
        </P>
      )}
    </Box>
  </Card>
);

PageLinkCard.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    heading: PropTypes.string,
    image: cmsImagePropTypes,
    link: PropTypes.string,
  }).isRequired,
};

export { PageLinkCard };
