import PropTypes from 'prop-types';
import React from 'react';
import StyledSystemPropTypes from '@styled-system/prop-types';

import { Flex } from '../grid';
import { CardImage } from './card-image';
import { cmsImagePropTypes } from '../../prop-types';
import { ConditionalLink } from './conditional-link';
import { Wrapper } from './styles';

const Card = ({
  as,
  children,
  backgroundColor,
  contentHeight,
  contentWidth,
  flexDirection,
  flexWrap,
  href,
  image,
  imageHeight,
  imageWidth,
  isOutOfStock,
  justifyContent,
  onClick,
  overlayContent,
  padding,
  isEligibleForDeliveryDiscount,
  isDiscountApplied,
  deliveryDiscountSpacing,
  isItemInCart,
  borderRadius,
  renderInsteadOfImage,
  isDisabled,
  isLoading,
}) => (
  <ConditionalLink href={href} as={as}>
    <Wrapper
      variant={backgroundColor}
      flexDirection={flexDirection}
      flexWrap={flexWrap}
      height="100%"
      onClick={onClick}
      overlayContent={overlayContent}
      position="relative"
      role={onClick ? 'button' : undefined}
      width="100%"
    >
      {renderInsteadOfImage || (
        <CardImage
          image={image}
          height={imageHeight}
          width={imageWidth}
          position={overlayContent ? 'absolute' : 'static'}
          borderRadius={borderRadius}
          isItemInCart={isItemInCart}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isOutOfStock={isOutOfStock}
          isEligibleForDeliveryDiscount={isEligibleForDeliveryDiscount}
          deliveryDiscountSpacing={deliveryDiscountSpacing}
          isDiscountApplied={isDiscountApplied}
        />
      )}

      <Flex
        flexDirection="column"
        height={contentHeight}
        justifyContent={justifyContent}
        p={padding}
        width={contentWidth}
        position={overlayContent ? 'absolute' : 'static'}
      >
        {children}
      </Flex>
    </Wrapper>
  </ConditionalLink>
);

Card.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  backgroundColor: PropTypes.oneOf(['light', 'dark', 'transparent']),
  contentHeight: PropTypes.oneOfType([PropTypes.string, StyledSystemPropTypes.layout.height]),
  contentWidth: StyledSystemPropTypes.layout.width,
  flexDirection: StyledSystemPropTypes.flexbox.flexDirection,
  flexWrap: StyledSystemPropTypes.flexbox.flexWrap,
  href: PropTypes.string,
  image: PropTypes.oneOfType([cmsImagePropTypes, PropTypes.arrayOf(cmsImagePropTypes)]),
  imageHeight: StyledSystemPropTypes.layout.height,
  imageWidth: StyledSystemPropTypes.layout.width,
  isOutOfStock: PropTypes.bool,
  justifyContent: PropTypes.string,
  onClick: PropTypes.func,
  overlayContent: PropTypes.bool,
  padding: StyledSystemPropTypes.space.padding,
  isEligibleForDeliveryDiscount: PropTypes.bool,
  isDiscountApplied: PropTypes.bool,
  deliveryDiscountSpacing: StyledSystemPropTypes.space.margin,
  borderRadius: PropTypes.number,
  isItemInCart: PropTypes.bool,
  renderInsteadOfImage: PropTypes.node,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

Card.defaultProps = {
  as: null,
  children: null,
  backgroundColor: 'light',
  contentHeight: '100%',
  contentWidth: undefined,
  flexDirection: 'row',
  flexWrap: 'wrap',
  href: undefined,
  image: null,
  imageHeight: undefined,
  imageWidth: undefined,
  isOutOfStock: false,
  justifyContent: 'space-between',
  onClick: undefined,
  overlayContent: false,
  padding: { xs: 1 },
  isEligibleForDeliveryDiscount: false,
  isDiscountApplied: false,
  deliveryDiscountSpacing: null,
  isItemInCart: false,
  borderRadius: 0,
  renderInsteadOfImage: null,
  isLoading: false,
  isDisabled: false,
};

export { Card };
